import React from "react";
import { Button, Form } from "react-bootstrap";
import SuccessModal from "../../../components/common/SuccessModal/SuccessModal";
import { Disclaimer, Subtitle, Title3 } from "../ContactHelperComponents";
import {
  CustomFormControlProps,
  DiscalimerProps,
  FormButtonProps,
  ContactSuccessModalProps,
} from "./ContactFormFieldsProps";

export const Label = ({
  id,
  title,
  isRequired,
}: {
  id: string;
  title: string;
  isRequired: boolean;
}) => {
  return (
    <Form.Label id={id}>
      {title}
      {isRequired && <span className="text-danger">*</span>}
    </Form.Label>
  );
};

export const FeedbackMessage = (error: any) => {
  return (
    <Form.Control.Feedback type="invalid" role="alert" aria-live="assertive">
      {error?.error?.message}
    </Form.Control.Feedback>
  );
};

export const CustomFormControl: React.FC<CustomFormControlProps> = ({
  isInvalid,
  as = "input",
  rows,
  ariaRequired,
  maxLength,
  ...props
}) => {
  return (
    <Form.Control
      isInvalid={isInvalid}
      as={as}
      rows={rows}
      aria-required={ariaRequired}
      maxLength={maxLength}
      {...props}
    />
  );
};

export const DisclaimerContainer: React.FC<DiscalimerProps> = ({
  handleTermsModalShow,
  handlePrivacyModalShow,
}) => {
  return (
    <Disclaimer className="mt-3 d-block">
      By submitting this form I certify that I have read, understand, and accept
      the{" "}
      <Button
        variant="link"
        className="modalLinks"
        onClick={handleTermsModalShow}
      >
        Terms of Service
      </Button>{" "}
      &nbsp;and&nbsp;
      <Button
        variant="link"
        className="modalLinks"
        onClick={handlePrivacyModalShow}
      >
        Privacy Statement
      </Button>
      .
    </Disclaimer>
  );
};

export const FormButton: React.FC<FormButtonProps> = ({ isSubmitting }) => {
  return (
    <Button
      type="submit"
      disabled={isSubmitting}
      className="text-uppercase w-100 mt-2"
      id="submit-contact-us"
    >
      {isSubmitting ? `Please wait...` : `Contact Us`}
    </Button>
  );
};

export const ContactSuccessModal: React.FC<ContactSuccessModalProps> = ({
  show,
  onHide,
}) => {
  return (
    <SuccessModal show={show} onHide={onHide} id="contact-us-email-success">
      <Subtitle className="text-center mb-1">CONFIRMATION</Subtitle>
      <Title3 className="m-3">Thank you for contacting us.</Title3>
      <p className="p-2">
        Our team has received your message and will respond within 48 hours.
      </p>
      <p className="m-0">
        <i className="font-weight-bold">
          You will receive a confirmation email shortly.
        </i>
      </p>
    </SuccessModal>
  );
};
