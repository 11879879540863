import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "react-day-picker/lib/style.css";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import PrivacyModal from "../../components/common/PrivacyModal/PrivacyModal";
import TermsOfServiceModal from "../../components/common/TermsOfServiceModal/TermsOfServiceModal";
import { generateJWTToken } from "../../utils/auth0Client";
import {
  validateMaxLengthMessage,
  validateMinLengthMessage,
} from "../../utils/validations";
import { aside, formGroup, offset } from "./Contact.module.scss";
import ContactFormFields from "./ContactFormFields/ContactFormFields";
import { ContactSuccessModal } from "./ContactFormFields/ContactFormFieldsHelpers";
import { StyledContainer, Subtitle, Title1 } from "./ContactHelperComponents";

export const RedlionContactForm = () => {
  const { profile, crmBasicProfile } = useSelector(
    (state: any) => state?.member || {}
  );

  const phone = useSelector((state) => {
    if (state.member.crmBasicProfile) {
      return state.member.crmBasicProfile.phone;
    }
    return "";
  });

  const memberId = useSelector((state) => {
    if (state?.member?.crmBasicProfile) {
      return state?.member?.crmBasicProfile.memberId;
    }
    return "";
  });

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
    setError,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const required = "This field is required.";
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const handleSuccessClose = () => {
    setShowSuccess(false);
  };

  const handlePrivacyModalClose = () => {
    setShowPrivacyModal(false);
  };

  const handlePrivacyModalShow = () => {
    setShowPrivacyModal(true);
  };

  const handleTermsModalClose = () => {
    setShowTermsModal(false);
  };

  const handleTermsModalShow = () => {
    setShowTermsModal(true);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    if (!data.id) {
      setError("contactHotelCode", {
        message: "Please select hotel from suggested list.",
      });
      return;
    }

    const initialValues = getValues();
    const request = { ...initialValues, ...data };
    setErrorMessage("");
    setIsSubmitting(true);

    if (request.contactHotelCode) {
      delete request.contactHotelCode; //as this is not needed in contact api
    }
    try {
      const token = generateJWTToken(); // Assume this function is correctly implemented
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const url =
        process.env.GATSBY_SONESTA_FUNCTION_URL ||
        "https://functions.sonesta.com";
      const response = await fetch(`${url}/.netlify/functions/guestRelations`, {
        method: "POST",
        headers,
        body: JSON.stringify(request),
      });
      const result = await response.json();
      // eslint-disable-next-line no-console
      console.log("result === ", result);
      if (result?.error) {
        if (result?.error?.toLowerCase()?.includes("email")) {
          setError("email", {
            message: "Invalid email address",
          });
          setIsSubmitting(false);
          return;
        }
      }
      if (response.ok && result.data) {
        setShowSuccess(true);
        setValue("details", "");
      } else if (result.error) {
        setError("apiError", { message: result.error });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("apiError", {
        message: `An error occurred while creating a help ticket.
        Please try again.`,
      });
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (profile) {
      if (profile.firstName || crmBasicProfile.firstName) {
        setValue("firstName", profile.firstName || crmBasicProfile.firstName);
      }
      if (profile.lastName || crmBasicProfile.lastName) {
        setValue("lastName", profile.lastName || crmBasicProfile.lastName);
      }
      if (profile.email || crmBasicProfile.email) {
        setValue("email", profile.email || crmBasicProfile.email);
      }
      if (phone) {
        setValue("phone", phone);
      }
      if (memberId) {
        setValue("memberId", memberId);
      }
    }
  }, [profile, phone, setValue]);

  const handleHotelSelection = (data) => {
    setValue("id", data.hotel_code);
  };

  const values = getValues();

  return (
    <StyledContainer className="container-sm" fluid="sm">
      <Row>
        <Col lg={4} className={aside}>
          <Subtitle>Contact Us</Subtitle>
          <Title1 id="get-a-hold-of-us">Get a hold of us</Title1>
        </Col>
        <Col lg={{ offset: 1 }} className={offset}>
          {errorMessage && <Alert variant={`danger`}> {errorMessage}</Alert>}
          <ContactFormFields
            control={control}
            register={register}
            errors={errors}
            values={values}
            formGroup={formGroup}
            handleHotelSelection={handleHotelSelection}
            validateMaxLengthMessage={validateMaxLengthMessage}
            validateMinLengthMessage={validateMinLengthMessage}
            handleTermsModalShow={handleTermsModalShow}
            handlePrivacyModalShow={handlePrivacyModalShow}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            required={required}
          />
        </Col>
      </Row>
      <PrivacyModal show={showPrivacyModal} onHide={handlePrivacyModalClose} />
      <TermsOfServiceModal
        show={showTermsModal}
        onHide={handleTermsModalClose}
      />
      <ContactSuccessModal show={showSuccess} onHide={handleSuccessClose} />
    </StyledContainer>
  );
};
